
import React from "react"

const WorkoutControls = (props) => {

	const button = (key) => ({
			'unstarted' : <i className='workout--controls--toggle far fa-play-circle fa-2x' onClick={props.setStarted}></i>,
			'started'   : <i className="workout--controls--toggle far fa-pause-circle fa-2x" onClick={props.setPaused}></i>,
			'paused'    : <i className="workout--controls--toggle far fa-play-circle fa-2x" onClick={props.setStarted}></i>,
			'complete'  : <i className="workout--controls--toggle fas fa-angle-double-left fa-2x" onClick={props.setUnstarted}></i>
		})[key]
	
	return(<div className={'workout--controls'}>{button(props.interactionState)}</div>)
}

export default WorkoutControls
