import React from "react"
import _ from "lodash"

function WorkoutConfiguration(props) {

	function closedConfiguration(){
		return(
			<div className='workout--configuration--closed'>				
				<a className='workout--configuration--menu' 
				   href='#' 
				   onClick={()=>{props.setConfigurationIsOpen(true)}}>config
				</a>
			</div>
		)
	}

	// for a given value of index, return a function that takes e as a parameter
	// see nice walkthrough here: https://stackoverflow.com/questions/37763828/javascript-es6-double-arrow-functions/54839755#54839755
	const updateFieldChanged = (index) => (e) => {
		const newArr = [...props.exhortationTexts]
		newArr[index] = e.target.value
		props.setExhortationTexts(newArr)
	}

	const removeExhortation = (index) => (e) => {
		const newArr = [...props.exhortationTexts]
		_.pullAt(newArr, index)		
		props.setExhortationTexts(newArr)
	}

	const addExhortation = () => {
		const newArr = [...props.exhortationTexts]
		newArr.push('')
		props.setExhortationTexts(newArr)
	}

	const exhortationListItems = props.exhortationTexts.map((text, index) => {
		return(
			<div className='workout--configuration--exhortation' key={index}>							
				<i className='workout--configuration--remove-exhortation fas fa-times'
				   onClick={removeExhortation(index)} />
				<input key={text} 
					   className='workout--configuration--exhortation' 
					   name={'exhortation' + index} defaultValue={text} 
					   onBlur={updateFieldChanged(index)} />
			</div>
		)
	})

	function openConfiguration() {
		return(
			<div className='workout--configuration--open'>
				<a className='workout--configuration--menu' href='#' onClick={()=>{props.setConfigurationIsOpen(false)}}>config</a>
				<div className='workout--configuration--exhortations'>					
					{exhortationListItems}
					<div className='workout--configuration--exhortation' key='new'>
						<i className='workout--configuration--new-exhortation fas fa-plus'
					   	   title='Add new exhortation' 
					       onClick={addExhortation}
					   />
					</div>
				</div>
			</div>
		)
	}

	return(props.configurationIsOpen ? openConfiguration() : closedConfiguration())
}

export default WorkoutConfiguration
