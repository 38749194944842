import React, { useState, useEffect } from "react"
import _ from "lodash"

import countdownBeep from "./sound/countdownBeep.wav"
import startAndStopBeep from "./sound/startAndStopBeep.m4a"

// Handles visuals, voice, and timing - including warmup time - for a single exercise
const ExerciseTimer = (props) => {

	const exerciseLength = process.env.NODE_ENV === 'development' ? 10000 : 30000
	const restingLength = process.env.NODE_ENV === 'development' ? 5000 : 5000
	const warmUpLength = process.env.NODE_ENV === 'development' ? 3000 : 10000 // TODO: figure out how to handle this special case

    const [milliseconds, setMilliseconds] = useState(restingLength)
    const roundedSeconds = (milliseconds) => Math.round(milliseconds / 1000)

	const [exerciseState, setExerciseState] =  useState('resting') // valid states: 'resting', exercising'

	useEffect(() => {
		let interval = null

		if (exerciseState === 'resting' && props.interactionState === 'started') {

			interval = setInterval(() => {
					setMilliseconds(milliseconds - 100)
			}, 100)

			// announce the name of the upcoming exercise
			if(milliseconds === restingLength - 1000) {		
				props.speakExerciseName()		

			// start 3-2-1 countdown timer noise
			} else if (milliseconds === 3000) {
				new Audio(countdownBeep).play()

			// exercise begins; ring starting bell
			} else if (milliseconds === 0) {
				setExerciseState('exercising')
				new Audio(startAndStopBeep).play()
			}
		}
	
		if (exerciseState === 'exercising' && props.interactionState === 'started') {			
			interval = setInterval(() => {
		  		setMilliseconds(milliseconds + 250)
			}, 250)
		
			if(milliseconds === 2000) {
				startExerciseExhortation()

			// exercise complete
			} else if(milliseconds === exerciseLength) {			
				new Audio(startAndStopBeep).play()
				props.exerciseComplete()
			}
		}

		return function cleanup() { clearInterval(interval) }
	  }, [milliseconds, exerciseState, props.interactionState, props.exerciseName])
	
	function startExerciseExhortation() { 					
		const textToUtter = props.exhortationTexts[Math.floor(Math.random() * props.exhortationTexts.length)]
		const utterance = new window.SpeechSynthesisUtterance(textToUtter)
		utterance.voice = window.speechSynthesis.getVoices().find(e => { return e.name === "Alex" })
		window.speechSynthesis.speak(utterance)
	}

	function secondsCircles(milliseconds, totalMilliseconds) {	
		console.log('hi! ms = ' + milliseconds)
		console.log('hi! total ms = ' + totalMilliseconds)
		const numCircles = 60
		const bigInvisibleCircleRadius = 50
		const arr = _.range(numCircles)
		let result = arr.map((foo, index) => {
			const angle = (360/numCircles)*(index + 1) // plus one so we start at :01, not :00 on the clockface for a 60-circle display
			const angleInRadians = angle * (Math.PI/180)			
			const cx = bigInvisibleCircleRadius * Math.sin(angleInRadians)
			const cy = bigInvisibleCircleRadius * Math.cos(angleInRadians) * -1 // since "up" is a negative number in svg's coordinate system
            const millisecondsPerCircle = totalMilliseconds / numCircles
			console.log('ms per circle = ' + millisecondsPerCircle)
            const circleStatus = (index <= Math.abs(milliseconds / millisecondsPerCircle)) ? 'elapsed' : 'remaining' // we use abs() to account for the negative milliseconds of the clock during the resting phase
            return(
				<circle 
					key={index}
					cx={cx} 
					cy={cy}
					r="1.5"
					className={'workout--timer-circle ' + circleStatus}
				/>
			)}
		)
		return result
	}

	return(
		<div>
			<h1 className={'workout--current-exercise-name ' + exerciseState}>
				{props.exerciseName}
			</h1>              

			<svg viewBox="-100 -75 200 150" xmlns="http://www.w3.org/2000/svg">
				<text					
					dominantBaseline="middle"
					textAnchor="middle"
					className={'workout--current-exercise-timer ' + exerciseState}>					
					{exerciseState === 'resting' && roundedSeconds(milliseconds)}
				</text>

				{exerciseState === 'resting'
				 ? secondsCircles(restingLength - Math.abs(milliseconds), restingLength) // to convert the negative milliseconds we use internally into a positive number
				 : secondsCircles(milliseconds, exerciseLength)
				}

			</svg>
		</div>
	)
}

export default ExerciseTimer
