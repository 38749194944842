import React, { useState, useEffect } from "react"
import { graphql } from "gatsby"
import _ from "lodash"

import Layout from "../components/layout"
import WorkoutConfiguration from "../components/7mw/WorkoutConfiguration"
import WorkoutControls from "../components/7mw/WorkoutControls"
import ExerciseTimer from "../components/7mw/ExerciseTimer"

const SevenMin = ({ data, pageContext }) => {

	const [exerciseId, setExerciseId] = useState(0)
	const [interactionState, setInteractionState] = useState('unstarted') // acceptable values: unstarted, started, paused, complete
	const [exhortationTexts, setExhortationTexts] = useState(['Go, go!', 'You. Are. Loved.', 'Keep working hard, humanoid'])
	const [configurationIsOpen, setConfigurationIsOpen] = useState(false)
	const exercises = [
		'Jumping Jacks',
		'Wall Sit',
		'Push-up',
		'Abdominal Crunch',
		'Chair Step-up',
		'Squat',
		'Triceps dip',
		'Plank',
		'High knees',
		'Lunge',
		'Push-up and rotation',
		'Side plank (left)',
		'Side plank (right)'
	]
	
	function exerciseName(exerciseId) {  	
		  return exercises[exerciseId]
	}

	function speakExerciseName(exerciseName) {
		const utterance = new window.SpeechSynthesisUtterance(exerciseName)
		window.speechSynthesis.speak(utterance)
	}
	
	function exerciseComplete(exerciseId) {
		if(exerciseId === exercises.length) {
			setInteractionState('complete')
		} else {
			setExerciseId(exerciseId + 1)
		}
	}

	function setUnstarted() {
		setInteractionState('unstarted')
		setExerciseId(0) // perhaps unfortunate that these are decoupled
	}

	return (
		<Layout> 
			<div className="row">
				<div className="col d-flex justify-content-center">
					<div className="post--inner"
						 style={{
							borderColor:  data.site.siteMetadata.defaultAccentColor,
							borderWidth: "3px",
							width: "720px",
							minHeight: "450px"
						 }}>
						
						<WorkoutConfiguration 
							configurationIsOpen={configurationIsOpen}
							setConfigurationIsOpen={setConfigurationIsOpen}
							exhortationTexts={exhortationTexts}
							setExhortationTexts={setExhortationTexts}
						/>

						<WorkoutControls 
							interactionState={interactionState} 
							setStarted={() => setInteractionState('started')}
							setPaused={() => setInteractionState('paused')}
							setUnstarted={setUnstarted}
						/>

						{interactionState === 'complete'
							? <h1 className='workout--good-job'>&#128079; &#128079; &#128079;</h1> 
							: <ExerciseTimer 
						 		key={exerciseName(exerciseId)}
								exerciseName={exerciseName(exerciseId)}
								interactionState={interactionState}	// perhaps trim down what we pass through...
								exhortationTexts={exhortationTexts}
								speakExerciseName={() => speakExerciseName(exerciseName(exerciseId))}
								exerciseComplete={() => exerciseComplete(exerciseId)}
							  />
						}
					</div>
				</div>
			</div>
		</Layout>
	)
}

export default SevenMin

export const query = graphql`
	query {
	    site {
    		siteMetadata {
      			defaultAccentColor
    		}
		}
	}
`
